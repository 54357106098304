import { Controller } from "stimulus"
import InfiniteScroll from "infinite-scroll"
export default class extends Controller {
  connect() {
    const options = {
      path: ".pagination__next",
      append: this.itemClass,
      history: false,

      status: ".page-load-status",
    }

    if (this.element.dataset.type === "button") {
      options.button = ".view-more-button"
      options.scrollThreshold = false
    } else {
      options.elementScroll = this.element
    }

    this.infScroll = new InfiniteScroll(this.element, options)

    if (this.element.dataset.type === "button") {
      const button = document.querySelector(".view-more-button")
      this.infScroll.on("request", () => button.classList.add("hidden"))
      this.infScroll.on("append", d => button.classList.remove("hidden"))
    }
  }

  disconnect() {
    this.infScroll.destroy()
  }

  get itemClass() {
    return this.element.dataset.itemClass
  }
}
