import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.remove("hide")
    }, 500)

    let handler
    this.element.addEventListener(
      this.animationEndEventName,
      (handler = () => {
        this.element.removeEventListener(this.animationEndEventName, handler)

        setTimeout(() => {
          this.element.classList.replace("bounceInRight", "bounceOutRight")
          this.element.addEventListener(this.animationEndEventName, () => {
            this.element.remove()
          })
        }, 5000)
      })
    )
  }

  get animationEndEventName() {
    const animations = {
      animation: "animationend",
      OAnimation: "oAnimationEnd",
      MozAnimation: "mozAnimationEnd",
      WebkitAnimation: "webkitAnimationEnd"
    }

    let elem = document.createElement("div")
    for (var t in animations) {
      if (elem.style[t] !== undefined) {
        return animations[t]
      }
    }
  }
}

export const toast = (message, type = "notice") => {
  const toastTemplate = `
   <div class="toast toast--${type} animated bounceInRight" data-controller="toast">
    ${message}
   </div>
 `

  document
    .querySelector(".toast__container")
    .insertAdjacentHTML("beforeend", toastTemplate)
}
