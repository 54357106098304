import SearchController from "./search_controller"

export default class extends SearchController {
  static targets = ["select"]

  reset = e => {
    e.preventDefault()
    this.selectTarget.options.selectedIndex = 0
    this.element.classList.remove("filter--active")
    this.search()
  }
}
