import Cleave from "cleave.js"

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    new Cleave(this.element, this.options)
  }

  get options() {
    const opt = {
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
      numeralDecimalScale: 0,
      numeralPositiveOnly: true,
    }

    if (this.element.dataset.addPrefix) {
      opt.prefix = "$"
    }

    return opt
  }
}
