import "flatpickr/dist/themes/light.css"
import flatpickr from "flatpickr"
import SearchController from "./search_controller"

export default class extends SearchController {
  connect() {
    this.fp = flatpickr(this.dateInput, {
      mode: "range",

      dateFormat: "m.d.Y",
      maxDate: "today",
      onChange: this.change.bind(this),
    })
  }

  change() {
    this.element.classList.toggle("filter--active", this.isDateRangeSelected())

    if (this.isDateRangeSelected()) {
      this.search()
    }
  }

  reset = e => {
    e.preventDefault()
    this.dateInput.value = ""
    this.element.classList.remove("filter--active")
    this.fp.clear(false, true)

    this.search()
  }

  isDateRangeSelected() {
    return this.fp.selectedDates.length === 2
  }

  get dateInput() {
    return this.element.querySelector("input")
  }
}
