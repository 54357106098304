import Turbolinks from "turbolinks"
import { Controller } from "stimulus"

export default class extends Controller {
  // connect() {
  //   debugger
  // }
  // method to determine if any link is active
  visit = e => {
    const url = e.target.selectedOptions[0].dataset.url

    Turbolinks.visit(url)
  }

  _hasActiveLink() {
    this.element.querySelector("select").selectedOptions
  }
}
