import { toast } from "./toast_controller"
import { Controller } from "stimulus"

export default class extends Controller {
  removeBlinkyAndShowToast = e => {
    const el = e.target.closest(".blinky-card")
    el.remove()
    toast(e.detail[0].message)
  }
}
