let animationEventName
const animations = {
  animation: "animationend",
  OAnimation: "oAnimationEnd",
  MozAnimation: "mozAnimationEnd",
  WebkitAnimation: "webkitAnimationEnd"
}

let elem = document.createElement("div")
for (var t in animations) {
  if (elem.style[t] !== undefined) {
    animationEventName = animations[t]
    break
  }
}

export default (el, classNames, callback) => {
  let handler
  el.addEventListener(
    animationEventName,
    (handler = e => {
      el.removeEventListener(animationEventName, handler)
      callback && callback(e)
    })
  )
  classNames = classNames.split(/\s+/)
  el.classList.add(...classNames)
}
