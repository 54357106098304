import StimulusHtml5validation from "stimulus-html5validation"

export default class extends StimulusHtml5validation {
  display({ el, error }) {
    const parent = el.closest(".basic-form__field-container")

    if (!parent) return

    const errorField = parent.querySelector(".basic-form__field-error")

    parent.classList.toggle("basic-form__field-container--error", !!error)

    if (!errorField) {
      if (error) {
        parent.insertAdjacentHTML(
          "afterbegin",
          `<div class="basic-form__field-error">${error}</div>`
        )
      }
    } else {
      errorField.textContent = error ? error : ""
    }
  }
}
