import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dropdown"]

  openDropdown = e => {
    this.dropdownTarget.classList.add("filter__dropdown--active")
  }

  closeDropdown = e => {
    if (this.element.contains(e.target)) return

    this.dropdownTarget.classList.remove("filter__dropdown--active")
  }

  save = e => this.dropdownTarget.classList.remove("filter__dropdown--active")
}
