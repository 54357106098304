import debonce from "lodash.debounce"
import { Controller } from "stimulus"

const strength = {
  0: "Weak",
  1: "Weak",
  2: "Weak",
  3: "Good",
  4: "Strong",
}

const strengthClasses = {
  0: "weak",
  1: "weak",
  2: "weak",
  3: "good",
  4: "strong",
}

export default class extends Controller {
  static targets = [
    "suggestions",
    "meter",
    "warning",
    "score",
    "inputValidator",
  ]

  connect() {
    this._loadZxcvbnScript()

    this._setupTooltip()
    this.measure = debonce(this._measure, 150)
  }

  _testPassword(val) {
    const result = window.zxcvbn(val)

    return {
      score: result.score,
      warning: result.feedback.warning,
      suggestions: result.feedback.suggestions,
    }
  }

  _measure = (e) => {
    const value = e.target.value

    if (!value) {
      this._setupTooltip()
      return
    }

    const result = this._testPassword(value)

    const strengthText = strength[result.score]

    this.meterTarget.setAttribute(
      "class",
      `password-meter__inner-meter ${strengthClasses[result.score]}`
    )

    this.warningTarget.textContent = result.warning
    this.suggestionsTarget.innerHTML = result.suggestions
      .map((suggestion) => `<li>${suggestion}</li>`)
      .join("")
    this.scoreTarget.textContent = strengthText
  }

  _loadZxcvbnScript() {
    if (!window.zxcvbn) {
      const script = document.createElement("script")
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js"

      document.head.appendChild(script)
    }
  }

  validatePasswordStrength = (e) => {
    const score = e.target.value ? this._testPassword(e.target.value).score : 0
    const password = this.element.querySelector('[type="password"]')
    if (score < 3) {
      password.value = ""
      password.dispatchEvent(new Event("input"))
    }
  }

  _setupTooltip() {
    const tooltipTemplate = `
    <div class="password-meter">
      <div class="password-meter__strength"><span data-target="password-meter.score"></span></div>
      <div class="password-meter__meter">
        <span data-target="password-meter.meter" class="password-meter__inner-meter"></span>
      </div>
      <div class="password-meter__warning" data-target="password-meter.warning"></div>
      
      <ul data-target="password-meter.suggestions">
      </ul>
      
    </div>
  `

    this.element.querySelector(
      ".basic-form__tooltip"
    ).innerHTML = tooltipTemplate
  }
}
