import SearchController from "./search_controller"

export default class extends SearchController {
  reset = e => {
    this.element.querySelectorAll("input[type='checkbox").forEach(checkbox => {
      checkbox.checked = false
    })
    this.element.classList.remove("filter--active")
    const filterName = this.element.querySelector("input[type='checkbox']").name

    this.search(e, [filterName])
  }
}
