import Cleave from "cleave.js"
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    new Cleave(this.element, {
      blocks: [2, 7],
      numericOnly: true,
      delimiter: "-",
      delimiterLazyShow: true,
    })

    this.element.removeAttribute("maxlength")
    this.element.removeAttribute("size")
    this.element.removeAttribute("minlength")
  }
}
