import SearchController from "./search_controller"

export default class extends SearchController {
  static targets = ["lowest", "highest", "label"]

  reset = e => {
    e.preventDefault()
    this.lowestTarget.value = ""
    this.highestTarget.value = ""
    this.labelTarget.textContent = "Amount"
    this.element.classList.remove("filter--active")
    this.search()
  }
}
