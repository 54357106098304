import addAnimation from "../../utils/add_animation"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "mobileMenu", "header"]

  connect() {
    this.element.querySelectorAll("a").forEach(link => {
      link.classList.toggle("active", this._isActiveLink(link))
      link.addEventListener("click", this._closeNav)
    })
  }

  disconnect() {
    Array.from(this.element.querySelectorAll("a")).forEach(link => {
      link.removeEventListener("click", this._closeNav)
    })
  }

  openNav = e => {
    e.preventDefault()
    this.contentTarget.classList.add("is-open")
    this.headerTarget.classList.add("is-open")
    addAnimation(this.contentTarget, "animated slideInLeft", () => {
      this.contentTarget.classList.remove("animated", "slideInLeft")
    })
  }

  closeNav = () => {
    addAnimation(this.contentTarget, "animated slideOutLeft", () => {
      this.contentTarget.classList.remove("is-open")
      this.headerTarget.classList.remove("is-open")
      this.contentTarget.classList.remove("animated", "slideOutLeft")
    })
  }

  _toggleNavContent() {
    this.contentTarget.classList.toggle("is-open")
    this.headerTarget.classList.toggle("is-open")
    addAnimation(this.contentTarget, "animated bounceInLeft", () => {})
  }

  // _toggleNavIcon() {
  //   this.mobileMenuTarget.classList.toggle("fa-bars")
  //   this.mobileMenuTarget.classList.toggle("fa-times")
  // }

  _closeOpenLinkGroups() {
    this._openLinkGroups.forEach(linkGroup => {
      linkGroup.style.display = "none"

      this._linkGroupController(linkGroup).toggleContent()

      setTimeout(() => {
        linkGroup.style.display = "block"
      }, 700)
    })
  }

  _linkGroupController(linkGroup) {
    return this.application.getControllerForElementAndIdentifier(
      linkGroup.closest(".reveal"),
      "reveal"
    )
  }

  get _openLinkGroups() {
    return Array.from(
      this.contentTarget.querySelectorAll(".reveal__content.is-open")
    )
  }

  _isActiveLink(link) {
    return (
      (location.pathname !== "/" &&
        link.pathname.startsWith(this._currentPath)) ||
      this._isRootLinkActive(link)
    )
  }

  get _currentPath() {
    return "/" + location.pathname.split("/").filter(path => path)[0]
  }

  _isRootLinkActive(link) {
    return link.pathname === "/" && document.location.pathname === "/"
  }
}
