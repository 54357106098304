import { Controller } from "stimulus"

export default class extends Controller {
  connect() {}

  get textarea() {
    return this.element.querySelector("textarea")
  }

  autoExpand = () => {
    this.textarea.style.height = "inherit"
    this.textarea.style.height = this.expenedHeight() + "px"
  }

  collapseHeight = () => {
    this.textarea.style.height = "2rem"
  }

  expenedHeight() {
    const computed = window.getComputedStyle(this.textarea)

    return [
      computed.borderTopWidth,
      computed.borderBottomWidth,
      computed.paddingTop,
      this.textarea.scrollHeight,
      computed.paddingBottom
    ].reduce((sum, currentValue) => {
      return sum + parseInt(currentValue, 10)
    }, 0)
  }
}
