import { Controller } from "stimulus"
import { toast } from "./toast_controller"

export default class extends Controller {
  showToast() {
    toast(this.element.dataset.message, {
      type: this.element.dataset.type || "notice",
    })
  }
}
