import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "triggerIcon"]

  toggleContent = e => {
    if (document.body.dataset.noReveal) {
      return
    }

    e && e.preventDefault()

    this.contentTarget.classList.toggle("is-open")
    this.rotateIcon()
    this._fetchContent()
  }

  rotateIcon() {
    if (!this.hasTriggerIconTarget) return

    this.triggerIconTarget.style.transform = `rotate(${this.rotateAmount()}deg)`
  }

  rotateAmount() {
    const currentDegree = Number(
      this.triggerIconTarget.style.transform.replace(/\D/g, "")
    )
    return currentDegree > 0 ? 0 : this.triggerIconTarget.dataset.rotateAmount
  }

  _fetchContent() {
    if (this.element.dataset.contentUrl && !this.data.has("contentLoaded")) {
      fetch(this.element.dataset.contentUrl)
        .then(response => response.text())
        .then(content => {
          this.contentTarget.innerHTML = content
          this.data.set("contentLoaded", true)
        })
    }
  }
}
