import flatpickr from "flatpickr"
import SearchController from "./search_controller"

export default class extends SearchController {
  static targets = [
    "startDate",
    "endDate",
    "startDateContainer",
    "endDateContainer",
  ]

  connect() {
    this.fpStart = flatpickr(this.startDateTarget, {
      dateFormat: "m.d.Y",
      maxDate: "today",
      onChange: this.changeStart.bind(this),
    })
    this.fpEnd = flatpickr(this.endDateTarget, {
      dateFormat: "m.d.Y",
      maxDate: "today",
      onChange: this.changeEnd.bind(this),
    })

    if (this.fpEnd.selectedDates[0]) {
      this.fpStart.set("maxDate", this.fpEnd.selectedDates[0])
    }

    if (this.fpStart.selectedDates[0]) {
      let date = this.fpStart.selectedDates[0]

      this.fpEnd.set("minDate", date.setDate(date.getDate() + 1))
    }
  }

  changeEnd = () => {
    if (this.hasEndDateContainerTarget) {
      this.endDateContainerTarget.classList.toggle("filter--active")
    }

    this.search()
  }

  changeStart = () => {
    if (this.hasStartDateContainerTarget) {
      this.startDateContainerTarget.classList.toggle("filter--active")
    }

    this.search()
  }

  resetAll = (e) => {
    e.preventDefault()
    this.endDateTarget.value = ""
    if (this.hasEndDateContainerTarget) {
      this.endDateContainerTarget.classList.remove("filter--active")
    }

    this.startDateTarget.value = ""
    if (this.hasStartDateContainerTarget) {
      this.startDateContainerTarget.classList.remove("filter--active")
    }
    this.fpStart.clear(false, true)
    this.fpEnd.clear(false, true)

    this.search()
  }

  resetEnd = (e) => {
    e.preventDefault()
    this.endDateTarget.value = ""
    if (this.hasEndDateContainerTarget) {
      this.endDateContainerTarget.classList.remove("filter--active")
    }
    this.fpEnd.clear(false, true)

    this.search()
  }

  resetStart = (e) => {
    e.preventDefault()
    this.startDateTarget.value = ""
    if (this.hasStartDateContainerTarget) {
      this.startDateContainerTarget.classList.remove("filter--active")
    }
    this.fpStart.clear(false, true)

    this.search()
  }
}
