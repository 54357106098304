import SimpleMaskMoney from "simple-mask-money"
import Cleave from "cleave.js"
import "cleave.js/dist/addons/cleave-phone.us"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["currencyInput"]

  connect() {
    this.installCurrencyMask()
    this.installCleaveOnTelInputs()
    this.installCleaveOnZipInputs()
  }

  focusField = ({ target }) => {
    const container = this.container(target)
    container.classList.add("basic-form__field-container--focus")
    this.toogleTooltip(container, true)
  }

  removeFocus = ({ target }) => {
    const container = this.container(target)
    container.classList.remove("basic-form__field-container--focus")
    this.toogleTooltip(container, false)
  }

  container(el) {
    return el.closest(".basic-form__field-container")
  }

  toogleTooltip(container, bool) {
    const tooltip = container.querySelector(".basic-form__tooltip")

    if (tooltip) {
      tooltip.classList.toggle("basic-form__tooltip--active", bool)
    }
  }

  installCurrencyMask() {
    this.currencyInputTargets.forEach(element => {
      SimpleMaskMoney.setMask(element, {
        prefix: "$",
        suffix: "",
        fixed: true,
        fractionDigits: 2,
        decimalSeparator: ".",
        thousandsSeparator: ",",
        cursor: "end",
      })
    })
  }

  installCleaveOnTelInputs() {
    this.element.querySelectorAll("[type='tel']").forEach(input => {
      new Cleave(input, {
        phone: true,
        phoneRegionCode: "US",
        delimiter: ".",
      })
    })
  }

  installCleaveOnZipInputs() {
    this.element.querySelectorAll("[data-zip-input]").forEach(input => {
      input.maxLength = 10

      new Cleave(input, {
        blocks: [5, 4],
        numericOnly: true,
        delimiter: "-",
        delimiterLazyShow: true,
      })
    })
  }
}
