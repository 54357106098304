import Turbolinks from "turbolinks"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field"]

  get form() {
    if (this.element.nodeName === "FORM") {
      return this.element
    } else {
      return this.element.querySelector("form") || this.element.closest("form")
    }
  }

  perform = (e) => this.search(e)

  search = (e, existingParamsToRemove = []) => {
    e && e.preventDefault()
    let params = ""
    let urlString

    if (e && e.target.dataset.onlySearch) {
      const searchEl = this.form.querySelector(
        `[name='${e.target.dataset.onlySearch}']`
      )
      urlString = `${document.location.pathname}?${searchEl.name}=${searchEl.value}`
    } else {
      const url = new URL(this.actionUrl(e && e.target))

      const formData = new FormData(this.form)
      formData.forEach((value, key) => {
        if (value) {
          params = this.str_serialize(params, key, value)
        }
        url.searchParams.delete(key)
      })
      existingParamsToRemove.forEach((p) => url.searchParams.delete(p))
      url.searchParams.delete("page")
      urlString = this.appendQuery(url.toString(), params)
    }

    Turbolinks.visit(urlString)
  }

  appendQuery(url, query) {
    return `${url}${url.includes("?") ? "&" : "?"}${query}`
  }

  str_serialize(result, key, value) {
    // encode newlines as \r\n cause the html spec says so
    value = value.replace(/(\r)?\n/g, "\r\n")
    value = encodeURIComponent(value)

    // spaces should be '+' rather than '%20'.
    value = value.replace(/%20/g, "+")
    return result + (result ? "&" : "") + encodeURIComponent(key) + "=" + value
  }

  actionUrl(button) {
    if (button && button.formAction) return button.formAction

    return this.form.action
  }

  clearSearchField = (e) => {
    e.preventDefault()
    this.fieldTarget.value = ""
  }
}
