import addAnimation from "../../utils/add_animation"
import { toast } from "./toast_controller"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["contentWrapper", "content", "contentContainer"]

  open() {
    this._setupModal()

    this._fetchForm()
  }

  insertForm = ({ detail }) => {
    this.contentTarget.innerHTML = detail[0].body.innerHTML
  }

  close = (e, callback) => {
    if (e) {
      e.stopPropagation()

      if (this.closingModalNotAllowed()) {
        return
      }

      if (e.target !== e.currentTarget) {
        return
      }
    }

    addAnimation(this.contentContainerTarget, "animated 	bounceOutUp", () => {
      this.element.classList.remove("form-modal--is-open")
      this.contentWrapperTarget.innerHTML = ""
      this.contentWrapperTarget.remove()
      callback && callback()
    })
  }

  closingModalNotAllowed() {
    return !!document.querySelector("[data-disallow-closing-modal]")
  }

  closeAndDisplayToast() {
    this.close(null, () => {
      toast(this.element.dataset.toastMessage)
    })
  }

  _setupModal = () => {
    this.element.classList.add("form-modal--is-open")
    this.element.insertAdjacentHTML(
      "beforeend",
      `
<div class="w-full h-full flex items-center md:mt-0" 
    data-target="form-modal.contentWrapper" 
    data-action="click->form-modal#close">
    
    <div data-target="form-modal.contentContainer" class="form-modal__content one-column-container-sm md:shadow-2xl w-full">
      <i class="form-modal__close far fa-times" data-action="click->form-modal#close" ></i>
      <div data-target="form-modal.content" data-action="ajax:error->form-modal#insertForm"></div>
    </div>
</div>
    `
    )
    addAnimation(this.contentContainerTarget, "animated 	bounceInDown", () => {
      this.contentContainerTarget.classList.remove("animated", "bounceInDown")
    })
  }

  _fetchForm = () => {
    const loader = setTimeout(() => {
      this.contentTarget.classList.add("loading")
      this.contentTarget.innerHTML = `<div class="m-auto text-gray-500">Loading...</div>`
    }, 100)

    fetch(this.element.dataset.modalUrl)
      .then((response) => response.text())
      .then((content) => {
        this.contentTarget.classList.remove("loading")
        this.contentTarget.innerHTML = content
        clearTimeout(loader)
      })
      .catch((error) => {
        console.error(error)
        this.contentTarget.innerHTML = `<div style="color: red" class="m-auto">An error occured</div>`
      })
  }
}
