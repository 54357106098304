import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  toggleContent = e => {
    e && e.preventDefault()

    this.contentTarget.classList.toggle("is-open")
    this.element.classList.toggle("is-open")
  }
}
